.product-card-container {
    max-width: 800px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
}
.product-card {
    border-radius: 15px;
    min-height: 600px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 4px 3px;
    padding-bottom: 100px;
    &-topImage {
        height: 120px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 15px;
    }
    &-button-container {
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translate(-50%, -50%)
    }
}

@media screen and (max-width: 768px){
    .product-card-container {
        grid-template-columns: repeat(1, 1fr);
    }
    .product-card {
        height: auto; 
        min-height: auto
    }
}