.products-section {
    .container {
        max-width: 1200px;
    }
    .osmiddle-section .section-inner {
        background-color: #f1f1f1;
    }
    .products-tabs {
        &.nav-tabs {
            border-bottom: 1px solid rgba(73, 192, 182,0.4);
        }
        & .nav-link {
            margin: 0;
            padding: 18px;
        }
        & .nav-link:hover {
            border-color: transparent;
            color: rgb(73, 192, 182);
        }
        & .nav-link.active {
         background-color: rgb(73, 192, 182);
         color: white !important;   
        }
      }
      .aboutbox {
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
        margin: 0 -15px;
    }
    .aboutboximg-in img {
        display: inline-block;
        margin: 0;
        max-width: 250px;
    }
    .aboutboximg-in {
        
        text-align: center;
        padding: 0 0 20px 0;
    }
    .aboutbox-img {
        max-width: 330px;
        width: 100%;
    }
    .aboutbox-col {
        float: left;
        padding: 0 15px;
        min-height: 1px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    .aboutbox-text {
        padding: 0 15px 0 35px;
        max-width: calc(100% - 330px);
        width: 100%;
    }
    .aboutbox {
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
        margin: 0 -15px;
    }
   
}

.osmiddle-section .container {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    position: relative;
    max-width:1100px;
    z-index: 99;
    padding-top: 90px;
}
.osmiddle-section .entry-content .vc_row, .osmiddle-section > .section-inner > .container > .vc_row {
    padding: 0 0 70px 0;
}
.vc_row {
    margin-left: -15px;
    margin-right: -15px;
}
.vc_row:after, .vc_row:before {
    content: " ";
    display: table;
}
.osmiddle-section .container .vc_row .vc_col-sm-4 {
    width: 40%;
}
.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.vc_column-inner::after, .vc_column-inner::before {
    content: " ";
    display: table;
}
.osmiddle-section h2.vc_custom_heading {
    color: #1c1d1d;
    font-size: 24px;
    line-height: 30px;
    padding: 0 0 10px 0;
    margin: 0;
}
.osmiddle-section .vc_col-sm-4 .wpb_single_image, .osmiddle-section .vc_col-sm-4 figure.vc_figure, .osmiddle-section .vc_col-sm-4 .vc_single_image-wrapper, .osmiddle-section .vc_col-sm-4 .vc_single_image-wrapper img {
    width: 100%;
}
.wpb_single_image .vc_figure {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    max-width: 100%;
}
.wpb_single_image.vc_align_left {
    text-align: center;
}
.wpb_single_image .vc_single_image-wrapper {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
}
.wpb_single_image img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}
.vc_row:after {
    clear: both;
}
.vc_column-inner::after {
    clear: both;
}
.vc_column-inner::after, .vc_column-inner::before {
    content: " ";
    display: table;
}
 .osmiddle-section .container .vc_row .vc_col-sm-8 {
    width: 60%;
}
.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.osmiddle-section .wpb_text_column {
    padding: 0 0 20px 0;
}
@media (min-width: 768px) {
    .vc_col-sm-1, .vc_col-sm-10, .vc_col-sm-11, .vc_col-sm-12, .vc_col-sm-2, .vc_col-sm-3, .vc_col-sm-4, .vc_col-sm-5, .vc_col-sm-6, .vc_col-sm-7, .vc_col-sm-8, .vc_col-sm-9 {
        float: left;
    }
}
@media (min-width: 768px) {
    .vc_col-sm-10 {
        width: 83.33333333%;
    }
}

@media (min-width: 768px) {
    .vc_col-sm-2 {
        width: 16.66666667%;
    }
}
.vc_column_container {
    padding-left: 0;
    padding-right: 0;
}
.osmiddle-section article.post-396 .vc_row:nth-child(2n) .vc_col-sm-4, .osmiddle-section article.post-401 .vc_row:nth-child(2n) .vc_col-sm-4, .osmiddle-section .container .vc_row:nth-child(2n) .vc_col-sm-4, #service-3 .osmiddle-section .container .vc_row:nth-child(2n) .vc_col-sm-4 {
    float: right;
}
.osmiddle-section article.post-396 .vc_row:nth-child(2n) .vc_col-sm-8, .osmiddle-section article.post-401 .vc_row:nth-child(2n) .vc_col-sm-8, .osmiddle-section article.post-401 .vc_row:nth-child(2n) .vc_col-sm-8 .vc_custom_heading, .osmiddle-section article.post-396 .vc_row:nth-child(2n) .vc_col-sm-8 .vc_custom_heading, .osmiddle-section .container .vc_row:nth-child(2n) .vc_col-sm-8, #service-3 .osmiddle-section .container .vc_row:nth-child(2n) .vc_col-sm-8, .osmiddle-section .container .vc_row:nth-child(2n) .vc_col-sm-8 .vc_custom_heading, #service-3 .osmiddle-section .container .vc_row:nth-child(2n) .vc_col-sm-8 .vc_custom_heading {
    text-align: left !important;
}

@media (min-width: 768px) {
    .vc_col-sm-3 {
        width: 25%;
    }
}
@media (min-width: 768px) {
    .vc_col-sm-9 {
        width: 75%;
    }
}