.footer {
  background: #091D3E;
  &-container {
    max-width: 1200px;
    margin: auto;
  }
  &-horizontal-line {
    height: 1px;
    width: 100%;
    background: #fff;
    position: relative;
    bottom: -25px,
  }
  &-social-lnk {
    height: 50px;
    width: 50px;
    display: block;
    border: 1px solid white;
    border-radius: 50%;
    position: relative;
    
    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }
  }
}

@media screen and (max-width: 768px){
  .footer {
    &-horizontal-line {
      display: none;
    }
    &-social-lnk {
      height: 35px;
      width: 35px;
  }
}
}

.email-container {
    display: flex;
    align-items: center;
    border: 2px solid #16233C;
    background-color: #16233C; /* Dark blue background */
    max-width: 400px;
    padding: 0;
  }
  
  .email-input {
    border: none;
    padding: 10px 15px;
    flex-grow: 1;
    background-color: #16233C;
    color: #76C9C8;
    font-size: 18px;
  }
  
  .email-input::placeholder {
    color: #76C9C8; /* Light blue placeholder color */
  }
  
  .email-button {
    background-color: #76C9C8; /* Light blue color for the button */
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .email-button span {
    font-size: 24px;
    color: #16233C; /* Dark blue arrow color */
  }
  
  .email-input:focus {
    outline: none;
  }
  
  .email-button:hover {
    opacity: 0.9;
  }
  