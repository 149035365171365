.nav-link {
    color: #000;
    text-decoration: none;
    margin: 0 .5rem;
    font-weight: 400;
    letter-spacing: 1px;
  }
  
  .nav-link.active {
    color: rgb(73, 192, 182) !important; /* Highlight color for active links */
  }