.rewards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 1200px;
    gap: 10px;
    margin: 140px auto auto auto;
    
}
.company-logo {
    height: 100px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px,
}

@media screen and (max-width: 768px){
    .rewards {
        grid-template-columns: repeat(1, 1fr);
        margin: 0px auto auto auto;
    }
}