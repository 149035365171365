.pillars {
    background: #DEF4F2;
    &-container {
        max-width: 1200px;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 26px;
        padding-bottom: 200px;
        &-card {
            border-radius: 15px;
            min-height: 480px;
            position: relative;

            &-topImage {
                height: 215px;
                background-size: cover;
                background-position: center;
                border-radius: 15px
            }
            &-icon {
                position: absolute;
                top: 90%;
                left: 50%;
                transform: translate(-50%,-50%);
                text-align: center
            }
        }
    }
}

@media screen and (max-width: 768px){
    .pillars {
        &-container {
            grid-template-columns: repeat(1, 1fr);
            padding-bottom: 0px;
            &-card {
                min-height: 450px;
                height: 450px;
            }
        }
    }
}