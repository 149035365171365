.banner-wrap {
    position: relative;
    &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 99;
        height: 185px;
        width: 100%;
        background: url("https://www.datocms-assets.com/142763/1727679231-blackgray-curve_1.png") no-repeat;
        background-size: 100% 100%;
        background-position: bottom right;
    }
}

.banner-inner {
    line-height: 0;
    position: relative;
    & .img-responsive {
        display: block;
        object-fit: cover;
        -moz-object-fit: cover;
        -webkit-object-fit: cover;
        margin: 0;
        width: 100%;
        height: 674px;
        max-width: 100%;
        vertical-align: middle;
    }
    &::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,0.4);
    }
}
.banner-text {
        position: absolute;
        top: 45%;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 999;
        transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        text-align: center;
}

@media screen and (max-width: 768px){
    .banner-wrap {
        &::before {
            height: 40px;
        }
    }
}

@media screen and (max-width: 1023px){
    .banner-wrap {
        &::before {
            height: 80px;
        }
    }
}
