.ourcustomers-section {
    .container {
        max-width: 1200px;
    }
    .section-inner {
        padding: 0 0 30px 0;
    }
}

.ourcustomers-row.row {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    padding: 0 0 20px 0;
    margin: 0 -20px;
}
.customer-img {
    text-align: center;
    line-height: 0;
    padding: 0 0 20px 0;
    margin: 0 auto;
    max-width: 120px;
    width: 100%;
}
.customer-img img {
    filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    margin: 0 auto;
    max-width: 100%;
}

.ourcustomers-row.row .col-md-2 {
    padding: 0 15px;
    width: 14.28%;
}

.ourcustomers-row.row .col-md-2 {
    padding: 0 10px;
}

@media only screen and (max-width: 1023px) {
    .ourcustomers-row.row .col-md-2 {
        padding: 0 10px;
    }
}
@media only screen and (max-width: 767px) {
    .ourcustomers-row.row .col-md-2 {
        width: 25%;
        float: left;
    }
}

@media only screen and (max-width: 767px) {
    .ourcustomers-row.row {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .customer-img {
        padding: 0 0 20px 0;
        height: 140px;
        display: table-cell;
        vertical-align: middle;
    }
}

.container:after {
    clear: both;
    content: "";
    display: block;
}