.ourteam-section {
    .container {
        max-width: 900px;
    }
    .founder-img {
        line-height: 0;
        padding: 0 0 60px 0;
    }
    .founder-img img {
        margin: 0 auto;
        width: 100%;
    }
}
.casestudies-section{
    .container {
        max-width: 900px;
    }
    .section-inner {
        padding: 0 0 30px 0;
    }
    .casestudies-row {
        padding: 0 0 80px 0;
    }
    .casestudies-row:last-child {
        padding: 0 0 0px 0;
    }
}
.casestudies-img {
    line-height: 0;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 0 65px 0 0;
    -moz-border-radius: 0 65px 0 0;
    -webkit-border-radius: 0 65px 0 0;
    overflow: hidden;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    img {
        border-radius: 0 65px 0 0;
        -moz-border-radius: 0 65px 0 0;
        -webkit-border-radius: 0 65px 0 0;
        object-fit: cover;
        -moz-object-fit: cover;
        -webkit-object-fit: cover;
        margin: 0;
        height: 220px;
        width: 100%;
    }
}

.casestudiescol-last .casestudies-btn {
    text-align: right;
}
.casestudies-row {
    display: flex;
  }
  
  .casestudiescol-first {
    order: 2;
  }
  
  .casestudiescol-last {
    order: 1;
  }
  
  
  .casestudies-row:nth-child(odd) .casestudiescol-first {
    order: 2;
  }
  
  .casestudies-row:nth-child(odd) .casestudiescol-last {
    order: 1;
  }
  
  
  .casestudies-row:nth-child(even) .casestudiescol-first {
    order: 1;
  }
  
  .casestudies-row:nth-child(even) .casestudiescol-last {
    order: 2;
  }

  @media only screen and (max-width: 767px) {
    .casestudies-row .col-md-6, .casestudies-section .casestudies-row:nth-child(2n) .casestudiescol-first {
        float: none;
    }
}

@media (max-width: 767px) {

    .casestudies-row {
      display: block;
    }
  
    .casestudiescol-first, .casestudiescol-last {
      order: unset;
      float: none; 
      width: 100%; 
    }
    .casestudies-img img {
        height: 220px;
    }
    .casestudies-img, .casestudies-img img {
        border-radius: 0 75px 0 0;
        -moz-border-radius: 0 75px 0 0;
        -webkit-border-radius: 0 75px 0 0;
    }
    .casestudiescol-first.col-md-6 {
        padding: 0 15px 20px 15px;
    }

    .casestudiescol-last .casestudies-btn {
        text-align: left;
    }
  }

  @media only screen and (max-width: 1023px) {
    .casestudies-img img {
        height: 240px;
    }
    .casestudies-img, .casestudies-img img {
        border-radius: 0 75px 0 0;
        -moz-border-radius: 0 75px 0 0;
        -webkit-border-radius: 0 75px 0 0;
    }
}
@media only screen and (max-width: 1024px) {
    .casestudies-img img {
        height: 275px;
    }
}