.products {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 800px;
    margin-top: -200px;
    background: #49C0B6;
    &-container {
        padding-top: 170px,
    }
}

@media screen and (max-width: 768px){
    .products {
        background-image: none;
        background: #49C0B6;
        height: auto;
        margin-top: 0px;
        &-container {
            padding-top: 0px,
        }
    }
}

@media screen and (min-width: 1440px){
    .products {
        background-size: contain,
    }
}