.custom-form {
    max-width: 600px;
    margin: auto;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .is-invalid {
    border-color: red;
  }
  
  .invalid-feedback {
    color: red;
  }
  
  .btn {
    background-color: #d0021b;
    color: white;
    border: none;
    padding: 10px 20px;
  }
  
  .btn:hover {
    background-color: #b0021b;
  }
  