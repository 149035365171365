.track-records {
    position: relative;
    margin-top: -160px;
    background-size: cover;
    background-position: center;
    height: 1000px;
    z-index: 1;
    background-repeat: no-repeat;
    &-container {
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-width: 1200px;
        margin: auto;
        width: 100%;
    }
    &-cards-container {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 26px,
    }
    &-card {
        background:#fff;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        border-bottom: 8px solid #49C0B6;
        position: relative;
        padding-bottom: 60px;
        &-button-container {
            position: absolute;
            bottom: 0%;
            left: 50%;
            transform: translate(-50%, -30%);
        }
    }
}


@media screen and (max-width: 768px){
    .track-records {
        background-image: none;
        margin-top: 0px;
        background: #091D3E;
        height: auto;
        &-container {
        position: relative;
        top: 0%;
        left: 0%;
        transform: translate(0%,0%);
        }
        &-cards-container {
            grid-template-columns: repeat(1,1fr);
        }
    }
}

@media screen and (min-width: 1440px){
    .track-records {
        background-size: contain,
    }
}